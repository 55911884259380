import { IBMLocale } from '../locale/mapValidLocale';

export function swapCountryAndLanguage(localeCode: IBMLocale) {
  // IBM's Drupal changes the url prefix to be `country-language`,
  // so we need to adapt the provided langcode.
  let swappedLocaleCode = localeCode;

  if (localeCode.indexOf('-') > -1) {
    swappedLocaleCode = `${localeCode.split('-')[1]}-${
      localeCode.split('-')[0]
    }` as IBMLocale;
  }

  return swappedLocaleCode;
}
