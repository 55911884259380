export default {
  title: '',
  resizable: true,
  height: '250px',
  width: '100%',
  toolbar: {
    enabled: false,
  },
  color: {
    scale: {
      value: '#FFBF00',
    },
  },
  gauge: {
    showPercentageSymbol: false,
    numberFormatter: function (val) {
      return '239ms';
    },
  },
};
