import ReactDOM from 'react-dom';
import NS1ROI from './components/NS1ROI';
import { IntlProvider } from 'react-intl';
import normalizeWidgetInput from 'src/common/normalizeWidgetInput';
import widgetDefinition from './NS1ROI.widget';
import { RenderFn } from 'src/types/widgets';
import 'carbon-components/css/carbon-components.min.css';

const render: RenderFn = async function (instanceId, langCode, _origin, cb) {
  const { element, locale, messages } = await normalizeWidgetInput(
    instanceId,
    langCode,
    widgetDefinition,
  );
  if (!element || !locale) {
    return;
  }
  const localeCode = element.getAttribute('data-localecode') || 'en-us';

  ReactDOM.render(
    <IntlProvider locale={locale} messages={messages} defaultLocale="en">
      <NS1ROI
        element={element}
        langCode={localeCode}
        test={false}
        countryToTest={'us'}
      />
    </IntlProvider>,
    element,
    () => cb(element),
  );
};

export default render;
