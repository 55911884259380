import { ReactElement, useState, useEffect } from 'react';
import { Column, Row, Modal } from 'carbon-components-react';
import '../style/FormModal.scss';
import { segmentsTracking } from '../lib/tracker';
import BusinessPartner from 'src/common/components/BusinessPartner/BusinessPartner';
import { getTranslationsModal } from '../hooks/widgets/useLang';

export interface thanksTable {
  group: string;
  value: string;
}

export interface FormModalProperties {
  pageTitle: string;
  object: string;
  productTitle: string;
  pageUrl: string;
  location: string;
  bpName: string;
  logo: string;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
  setCloseModal?: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal?: boolean;
  formId?: string;
  lang?: string;
}

export const FormModal = ({
  pageTitle,
  object,
  productTitle,
  pageUrl,
  location,
  bpName,
  logo,
  drawerOpen,
  setDrawerOpen,
  id,
  setCloseModal,
  closeModal,
  formId,
  lang,
}: FormModalProperties): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [showFormHeader] = useState(false);
  const formHeader = getTranslationsModal('formHeader', lang);

  /**
   * Function to clear urx-form
   */
  useEffect(() => {
    if (closeModal === true) {
      const element = id
        ? 'urx-form-container-'.concat(id)
        : 'urx-form-container-urx-form';
      const parentElement = document.getElementById(element);
      console.log(`Found <div> with id: `, element);
      if (parentElement) {
        if (setCloseModal) {
          setCloseModal(false);
        }
        const childNodes = parentElement.childNodes;
        console.log(`Found childNodes:`, childNodes);
        while (childNodes.length > 0) {
          parentElement.removeChild(childNodes[0]);
        }
        if (parentElement.childNodes.length === 0) {
          const newDiv = document.createElement('div');
          newDiv.id = id ? id : 'urx-form';
          newDiv.setAttribute('no-toc', 'true');
          parentElement.appendChild(newDiv);
          console.log(`Added <div>: `, newDiv);
        }
      }
    }
  }, [closeModal]);

  useEffect(() => {
    setShowModal(drawerOpen);
    // We need this temporary if condition to pilot the background header for these 2 forms only.
    // Later to remove and apply to all forms.
    if (
      (formId && formId.includes('QTE-sustaineis')) ||
      (formId && formId.includes('QTE-sustainenvizi')) ||
      (formId && formId.includes('QTE-sustainmaximo')) ||
      (formId && formId.includes('QTE-sustainmaximoroi'))
    ) {
      console.log(`QTE form detected.`, formId);
      const root = document.documentElement;
      root?.style.setProperty('--display_background', 'var(--banner)');
      root?.style.setProperty('--banner_text_left', '18px');
    }
  }, [formId, showFormHeader, drawerOpen]);

  return (
    <div className="URX_FORM">
      <Row>
        <Column sm={4} md={16} lg={16}>
          <Modal
            passiveModal={true}
            open={showModal}
            size={'lg'}
            onRequestClose={() => {
              segmentsTracking('CTA Clicked', {
                productTitle,
                pageTitle,
                object,
                pageUrl,
                resultValue: 'CTA clicked',
                CTA: 'Form modal closed on the corner',
                location,
                action: `Form modal closed`,
                field: '',
              });
              setShowModal(false);
              setDrawerOpen(false);
              if (setCloseModal) {
                setCloseModal(true);
              }
            }}
            onRequestSubmit={() => {
              segmentsTracking('CTA Clicked', {
                productTitle,
                pageTitle,
                object,
                pageUrl,
                resultValue: 'CTA clicked',
                CTA: 'Form submitted',
                location,
                action: `Form modal closed`,
                field: '',
              });
              setShowModal(false);
              setDrawerOpen(false);
              if (setCloseModal) {
                setCloseModal(true);
              }
              window.location.reload();
            }}
          >
            <div className="bx--row">
              <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
                {bpName ? (
                  <BusinessPartner bpName={bpName} logo={logo} urxForm={true} />
                ) : null}
              </div>
            </div>
            {showFormHeader ? (
              <div className="bx--row">
                <Column sm={4} md={16} lg={16}>
                  <div className="URX_FORM-mar6">
                    <h3>{formHeader}</h3>
                  </div>
                </Column>
              </div>
            ) : null}
            <Row>
              <Column sm={4} md={16} lg={16} className="URX_FORM-mar5">
                <div
                  id={`${
                    id
                      ? 'urx-form-container-'.concat(id)
                      : 'urx-form-container-urx-form'
                  }`}
                >
                  {/* Solution for having two urx form on same page */}
                  <div no-toc="true" id={`${id ? id : 'urx-form'}`}></div>
                </div>
              </Column>
            </Row>
          </Modal>
        </Column>
      </Row>
    </div>
  );
};
