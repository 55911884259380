import '../../style/common.scss';
import ReactMarkdown from 'react-markdown';

// Supports markdown-language for business partner descriptions. Refer https://commonmark.org/help/ for markdown formats.
export interface BusinessPartnerProps {
  bpName: string;
  logo: string;
  desc?: string;
  urxForm?: boolean;
}

// This is to validate the logo, to be more expediate when BPPR API is implemented
const validateLogo = (logo: string): string => {
  if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(logo.toLowerCase())) {
    return logo;
  } else return '';
};

const BusinessPartner = ({
  bpName,
  logo,
  desc,
  urxForm,
}: BusinessPartnerProps) => {
  return (
    <div className="business-partner">
      {!urxForm ? <hr className="business-partner__custom-hr" /> : null}
      <div className={`bp-grid ${urxForm ? 'bp-pad-bot-1' : ''}`}>
        {validateLogo(logo) ? (
          <div className={`bp-grid-logo ${!urxForm ? 'bp-mar-top-1' : ''}`}>
            <img
              alt={`${bpName}-Logo`}
              src={logo}
              className={`${urxForm ? 'bp-img-urxform' : 'bp-img'}`}
            />
          </div>
        ) : null}
        {urxForm && validateLogo(logo) ? null : (
          <div
            className={`bp-grid-name-desc ${
              !urxForm ? 'bp-mar-top-1' : 'bp-mar-top-less-1'
            } ${validateLogo(logo) ? 'bp-grid-name-desc-separator' : ''}`}
          >
            <div className="bp-name">{bpName}</div>
            {desc ? (
              <div className="bp-desc">
                <ReactMarkdown children={desc} />
              </div>
            ) : null}
          </div>
        )}
      </div>
      {!urxForm ? <hr className="business-partner__custom-hr" /> : null}
    </div>
  );
};

export default BusinessPartner;
