import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../locales/messages';
import { ThanksModal, thanksTable } from 'src/common/utils/ThanksModal';

export interface thanksProps {
  bpName: string;
  demo: string;
}

const Thanks = ({ bpName, demo }: thanksProps) => {
  const intl = useIntl();
  const [showThanksModal, setShowThanksModal] = useState(true);

  const titleThanks: thanksTable[] = [
    { group: 'title', value: intl.formatMessage(messages.thank_text) },
    {
      group: 'widgetTitle2',
      value: intl.formatMessage(messages.thank_desc1),
    },
  ];

  const widgetTitleThanks: thanksTable[] = [
    {
      group: 'widgetTitle1',
      value: intl.formatMessage(messages.thank_desc2),
    },
  ];
  const contentThanks: thanksTable[] = [];
  bpName &&
    contentThanks.push({
      group: String(intl.formatMessage(messages.bp_title)),
      value: bpName,
    });

  const buttonThanks: thanksTable[] = [
    {
      group: 'button1',
      value: String(intl.formatMessage(messages.return_roi)),
    },
  ];

  const demoContent = {
    demo,
    demoText: String(intl.formatMessage(messages.demo_text)),
  };

  return (
    <div>
      <ThanksModal
        showThanksModal={showThanksModal}
        pageTitle="NS1 ROI Page"
        object="ROI Page"
        productTitle={'NS1 ROI'}
        pageUrl={'/products/ns1/pricing'}
        location={'ROI'}
        titleThanks={titleThanks}
        widgetTitleThanks={widgetTitleThanks}
        contentThanks={contentThanks}
        buttonThanks={buttonThanks}
        setShowThanksModal={setShowThanksModal}
        link={demoContent}
      />
    </div>
  );
};

export default Thanks;
