import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'I7GgD+',
    defaultMessage: 'Performance Calculator',
  },
  introduction: {
    id: 'Z3ZU2q',
    defaultMessage:
      'Is your current authoritative DNS solution slowing you down? Choose your solution below to see how its speed stacks up against IBM NS1 Connect. Performance metrics are based upon a comprehensive analysis of worldwide DNS performance for the most popular providers.',
  },
  text1: {
    id: 'qM0sSr',
    defaultMessage:
      'How much faster will your applications be if you switch to NS1?',
  },
  text2: {
    id: '/WXbSw',
    defaultMessage: 'Select your current DNS solution:',
  },
  tile1text: {
    id: '44BuC9',
    defaultMessage:
      'I use an in-house, self-hosted solution for authoritative DNS',
  },
  tile2text: {
    id: 'LHvDNL',
    defaultMessage: 'I use a managed service other than NS1 Connect',
  },
  tile3text: {
    id: 'SZJHan',
    defaultMessage:
      'I use a basic domain registrar service for authoritative DNS',
  },
  tile1description: {
    id: 'BwRyhT',
    defaultMessage:
      'NS1 is 60% faster than the average self-hosted authoritative DNS solution',
  },
  tile2description: {
    id: 'WUMOE/',
    defaultMessage:
      'NS1 is 33% faster than the average managed authoritative DNS solution',
  },
  downloadButton: {
    id: 'YocjIT',
    defaultMessage: 'Download White Paper',
  },
  requestDemo: {
    id: 'olK/t4',
    defaultMessage: 'Request a live demo',
  },
  chart1Text: {
    id: '6sVT2W',
    defaultMessage: 'NS1 average response time',
  },
  chart2Text: {
    id: 'bKwo77',
    defaultMessage: 'Average self-hosted response time',
  },
  chart3Text: {
    id: 'rGM9FD',
    defaultMessage: 'Average premium service provider response time',
  },
  bp_title: {
    id: 'BkHBGW',
    defaultMessage: 'Selected Business Partner',
  },
  return_roi: {
    id: 'E//Dx8',
    defaultMessage: 'Return to ROI',
  },
  thank_text: {
    id: 'aYIUar',
    defaultMessage: 'Thank you!',
  },
  thank_desc1: {
    id: 'FVDZZf',
    defaultMessage:
      'One of our DNS experts will be in touch shortly to schedule a customized demo.',
  },
  thank_desc2: {
    id: 'Jtty3q',
    defaultMessage:
      'Want to learn more about NS1 Connect in the meantime? Visit our user community to access tutorials, blogs, and discussion forums with fellow network professionals.',
  },
  demo_text: {
    id: 'g0Ptc1',
    defaultMessage: 'Explore Community',
  },
  registrar_text: {
    id: 'r28E/z',
    defaultMessage:
      'Registrar DNS performance can be affected by a number of different factors. Learn more in our blog post.',
  },
  modalContinue: {
    id: 'rbrahO',
    defaultMessage: 'Close',
  },
  footnote: {
    id: 'gzN6/W',
    defaultMessage:
      'Want to learn more about how we gathered and analyzed this data? Download our white paper for an in-depth look.',
  },
  requestWhitepaper: {
    id: 'P1jLdx',
    defaultMessage: 'Download eBook',
  },
  readBlogPost: {
    id: 'ZfBOYW',
    defaultMessage: 'Read Blog Post',
  },
});

export default messages;
