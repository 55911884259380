import { IBMLocale } from 'src/common/locale/mapValidLocale';

/**
 *
 * @param environment
 * @param instanceId
 * @param formId
 * @param urxlang
 * @param bpid
 */
export const initUrxForm = (
  environment: string,
  instanceId: string,
  formId: string,
  urxlang: IBMLocale,
  bpid?: string,
) => {
  console.log(
    `Form parameters; environment=`,
    environment,
    'formId=',
    formId,
    'instanceId=',
    instanceId,
  );

  if (
    (document.getElementById(instanceId) as HTMLDivElement).childNodes
      .length === 0
  ) {
    if (window.renderUrxWidget) {
      window.urxEnvironment = environment;
      window.loadedUrxForm = false;
      window.renderUrxWidget(
        instanceId,
        formId,
        urxlang,
        {
          column: 3,
          theme: 'light',
          singleStep: true,
          triggerManually: false,
        },
        cb,
        bpid ? bpid : '',
      );
    }
  }
};

function cb(): boolean {
  console.log(`Form was loaded.`);
  window.loadedUrxForm = true;
  return true;
}
