import React, { ImgHTMLAttributes } from 'react';

export interface PublicImageProps extends ImgHTMLAttributes<unknown> {
  imgPath: string;
  widgetId: string;
  alt: string;
  x?: string;
  y?: string;
}

const PublicImage: React.FC<PublicImageProps> = ({
  imgPath,
  widgetId,
  ...props
}) => {
  const isWebpExtension = imgPath.endsWith('.webp');
  if (isWebpExtension) {
    return (
      <image
        href={
          imgPath && widgetId
            ? `${
                process.env.PUBLIC_ASSETS_URL ||
                process.env.STORYBOOK_PUBLIC_ASSETS_URL
              }/${widgetId}/${imgPath}`
            : undefined
        }
        {...props}
      />
    );
  }

  return (
    <img
      src={
        imgPath && widgetId
          ? `${
              process.env.PUBLIC_ASSETS_URL ||
              process.env.STORYBOOK_PUBLIC_ASSETS_URL
            }/${widgetId}/${imgPath}`
          : undefined
      }
      {...props}
    />
  );
};

export default PublicImage;
