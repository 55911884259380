import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import './NS1ROI.scss';
import { FormModal } from 'src/common/utils/FormModal';
import Thanks from '../components/Thanks/Thanks';
import 'src/common/style/common.scss';
import {
  urxScriptLoader,
  urxScript,
} from 'src/common/hooks/widgets/globalDefinitions';
import { useBPName } from 'src/common/hooks/widgets/useBPName';
import { UT30NS1ROI, data1, data2, data3, link } from '../constants/default';
import options1 from '../constants/options1.js';
import options2 from '../constants/options2.js';
import options3 from '../constants/options3.js';
import { swapCountryAndLanguage } from 'src/common/utils/swapCountryAndLanguage';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { DocumentDownload16, Launch16 } from '@carbon/icons-react';
import { initUrxForm } from 'src/common/hooks/widgets/urxForm';
import BusinessPartner from 'src/common/components/BusinessPartner/BusinessPartner';
import {
  TileGroup,
  RadioTile,
  Button,
  ButtonSet,
  Modal,
} from 'carbon-components-react';
import countryList from '../services/currencies.json';
import { useCookiesURL } from '../../../common/hooks/widgets/useCookiesURL';
import PublicImage from 'src/common/components/PublicImage';
import {
  useLang,
  LangDefaults,
  determineCountry,
} from 'src/common/hooks/widgets/useLang';
import messages from '../locales/messages';
import { segmentsTracking } from 'src/common/lib/tracker';
import { useEnv } from 'src/common/hooks/widgets/useEnv';
import * as URX_FORM from '../constants/urx-form';
import Cookies from 'js-cookie';
import { FormattedMessage, useIntl } from 'react-intl';
import { GaugeChart } from '@carbon/charts-react';
import '@carbon/charts/styles.css';

export interface AppProps {
  element: Element;
  langCode: string;
  test: boolean;
  countryToTest: string;
}

export type que5ValueProps = {
  options: {
    NTQ5A1: {
      value: string;
      checked: boolean;
    };
    NTQ5A2: {
      value: string;
      checked: boolean;
    };
    NTQ5A3: {
      value: string;
      checked: boolean;
    };
    NTQ5A4: {
      value: string;
      checked: boolean;
    };
    NTQ5A5: {
      value: string;
      checked: boolean;
    };
    NTQ5A6: {
      value: string;
      checked: boolean;
    };
  };
};

export type que6ValueProps = {
  options: {
    NTQ6A1: {
      value: string;
      checked: boolean;
    };
    NTQ6A2: {
      value: string;
      checked: boolean;
    };
    NTQ6A3: {
      value: string;
      checked: boolean;
    };
    NTQ6A4: {
      value: string;
      checked: boolean;
    };
    NTQ6A5: {
      value: string;
      checked: boolean;
    };
    NTQ6A6: {
      value: string;
      checked: boolean;
    };
    NTQ6A7: {
      value: string;
      checked: boolean;
    };
    NTQ6A8: {
      value: string;
      checked: boolean;
    };
    NTQ6A9: {
      value: string;
      checked: boolean;
    };
  };
};

export type QuesIDProps = {
  Q1?: string;
  Q2?: string;
  Q3?: string;
  Q4?: string;
  Q5?: string;
  Q6?: string;
  Q7?: string;
};

const NS1ROI = ({ langCode, test, countryToTest }: AppProps) => {
  const cookieURL = useCookiesURL();
  const localeCode = swapCountryAndLanguage(langCode as IBMLocale);
  const { bpName, companyId, logo, desc, mssp } = useBPName(UT30NS1ROI, true);
  const [formComplete, setFormComplete] = useState(false);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [, setCurrency] = useState('');
  const [, setCountry] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [lang, userLocale] = useLang(
    countryList,
    test,
    countryToTest,
    setCurrency,
    setCountry,
    localeCode,
  );
  const [, setBpConsentMail] = useState(false);
  const [selectedTile, setSelectedTile] = useState('');
  const [selectedTileText, setSelectedTileText] = useState('');
  // lang and userlocale is based out of ddo

  /* Have urx form deault in us-en when on language us page */
  const [, setOpenUrxModal] = useState(false);
  const [localCurrency, setlocalCurrency] = useState<string>(localeCode);
  const [, setToggleCountry] = useState(false);
  const [, setModalLocalCountry] = useState('');
  const [, setModalLocalCurrency] = useState('USD');
  const [, setModalLocalLanguage] = useState('English');
  const [, setLocalExist] = useState(false);
  const [registrarClick, setRegistrarClick] = useState(false);
  let localStorageFound = localStorage.getItem('langLocale');
  if (localStorageFound == null)
    localStorageFound = LangDefaults.localCountryLang;
  const [, setSelectedLocale] = useState(localStorageFound);
  // Determine Country
  determineCountry(
    setLocalExist,
    setSelectedLocale,
    setModalLocalCountry,
    setModalLocalLanguage,
    setModalLocalCurrency,
    setToggleCountry,
    lang,
    countryList,
    true,
    test,
    localeCode,
  );

  useEffect(() => {
    if (downloadClicked) {
      setDownloadClicked(false);
    }
  }, [downloadClicked]);

  useEffect(() => {
    setCookie();
  }, [selectedTile, selectedTileText]);

  const setCookie = () => {
    const mailCheckbox =
      document.querySelector<HTMLInputElement>('#Q_MAIL_CONSENT');
    setBpConsentMail(!!mailCheckbox?.checked); // set false if unchecked or if undefined
    Cookies.set(
      'urxdcq',
      JSON.stringify({
        source: URX_FORM.FORM_ID,
        // One field to provide all values (stringify)
        dcq: {
          Q_NOTESDATA:
            '|DIAC CI| NS1 ROI Calculator ' +
            cookieURL +
            ' | Tile selected: ' +
            selectedTile +
            ' | Selected tile text: ' +
            selectedTileText +
            ' | FormId:' +
            URX_FORM.FORM_ID +
            (getBp() ? `|Preferred BP:${getBp()}` : ''),
          Q_ASSET_NAME: '', // ISC - Asset Name
        },
      }),
    );
  };
  const getBp = () => {
    if (bpName) return bpName;
    if (logo) return companyId;
    if (desc) return desc;
    if (mssp) return mssp;

    const bpField = document.querySelector('#Q_BPNAME') as HTMLInputElement;
    return bpField ? bpField.value : '';
  };
  useEffect(() => {
    setlocalCurrency(userLocale); // show local Currency
  }, [userLocale, localeCode, localCurrency]);

  const [environment] = useEnv();
  window.urxEnvironment = environment;
  window.onUrxFormSubmitSuccess = () => {
    setFormComplete(!formComplete);
    setDrawerOpen(false);
  };

  const picture = 'images/NS1 Image.svg';

  const handleTileSelection = (
    selectedTile: string,
    selectedTileText: string,
  ) => {
    console.log(selectedTile, selectedTileText);
    setSelectedTile(selectedTile);
    setSelectedTileText(selectedTileText);
    if (selectedTile === 'tile1' || selectedTile === 'tile2') {
      segmentsTracking('CTA Clicked', {
        pageTitle: 'NS1 ROI Page',
        object: 'NS1 ROI page',
        resultValue: 'CTA clicked',
        CTA: 'Tile selection => ' + selectedTileText,
        location: 'ROI',
        action: '',
        field: '',
      });
    }
  };
  const intl = useIntl();
  return (
    <div className="ns1roi_widget widget-styles">
      <Helmet>
        <script type="application/javascript" src={urxScriptLoader}></script>
        <script type="application/javascript" src={urxScript}></script>
      </Helmet>

      <div className="bx--grid">
        {bpName ? (
          <BusinessPartner bpName={bpName} logo={logo} desc={desc} />
        ) : null}
        <div className="bx--row ns1roi_widget__mar5">
          <div className="bx--col-lg-8">
            <p className="page_sub_heading ns1roi_widget__marSm">
              <FormattedMessage {...messages.introduction} />
            </p>
          </div>
          <div className="bx--col-lg-8">
            <PublicImage
              imgPath={picture}
              widgetId="ns1-roi"
              id="img2"
              alt="NS1 ROI picture"
            />
          </div>
        </div>
        <div className="bx--row ns1roi_widget__ibm-pt-8">
          <div className="bx--col-lg-16">
            <p className="page_sub_heading">
              <FormattedMessage {...messages.text1} />
            </p>
          </div>
        </div>
        <div className="bx--row ns1roi_widget__mar5">
          <div className="bx--col-lg-16">
            <p className="page_sub_heading">
              <FormattedMessage {...messages.text2} />
            </p>
          </div>
        </div>
        <div className="bx--row ns1roi_widget__mar5">
          <div className="bx--col-lg-16 ns1roi_widget__marl-neg">
            <TileGroup
              className="ns1roi_widget__tilegroup"
              name="radio tile group"
            >
              <RadioTile
                className="ns1roi_widget__tileEn"
                key={'tile1'}
                id={'tile1'}
                value={'tile1'}
                onClick={() =>
                  handleTileSelection(
                    'tile1',
                    intl.formatMessage(messages.tile1text),
                  )
                }
              >
                <p className="page_body">
                  <FormattedMessage {...messages.tile1text} />
                </p>
              </RadioTile>
              <RadioTile
                className="ns1roi_widget__tileEn"
                key={'tile2'}
                id={'tile2'}
                value={'tile2'}
                onClick={() =>
                  handleTileSelection(
                    'tile2',
                    intl.formatMessage(messages.tile2text),
                  )
                }
              >
                <p className="page_body">
                  <FormattedMessage {...messages.tile2text} />
                </p>
              </RadioTile>
              <RadioTile
                className="ns1roi_widget__tileEn"
                key={'tile3'}
                id={'tile3'}
                value={'tile3'}
                onClick={() => {
                  setRegistrarClick(false);
                  handleTileSelection(
                    'tile3',
                    intl.formatMessage(messages.tile3text),
                  );
                  segmentsTracking('CTA Clicked', {
                    pageTitle: 'NS1 ROI Page',
                    object: 'NS1 ROI page',
                    resultValue: 'CTA clicked',
                    CTA: 'Tile selection => ' + selectedTileText,
                    location: 'ROI',
                    action: '',
                    field: '',
                  });
                }}
              >
                <p className="page_body">
                  <FormattedMessage {...messages.tile3text} />
                </p>
              </RadioTile>
            </TileGroup>
          </div>
        </div>
        {selectedTile === 'tile1' && (
          <div className="ns1roi_widget__mar3">
            <div className="bx--row padButton1">
              <div className="bx--col-lg-6">
                <p className="descText page_heading_3">
                  <FormattedMessage {...messages.tile1description} />
                </p>
              </div>
              <div className="bx--col-lg-5 chartText chart1 marTop">
                <GaugeChart data={data1} options={options1}></GaugeChart>
                <p>
                  <FormattedMessage {...messages.chart1Text} />
                </p>
              </div>
              <div className="bx--col-lg-5 chartText chart2 marTop">
                <GaugeChart data={data2} options={options2}></GaugeChart>
                <p>
                  <FormattedMessage {...messages.chart2Text} />
                </p>
              </div>
            </div>
            <div className="bx--row">
              <div className="bx--col-lg-6">
                <ButtonSet>
                  <Button
                    id="whitepaper_btn"
                    target="_blank"
                    className="ns1roi_widget__mar6"
                    href="https://www.ibm.com/account/reg/signup?formid=urx-52911"
                    kind="tertiary"
                    renderIcon={DocumentDownload16}
                  >
                    <FormattedMessage {...messages.requestWhitepaper} />
                  </Button>
                  <Button
                    id="demo_btn"
                    target="_self"
                    className="ns1roi_widget__mar6"
                    href="#"
                    kind="primary"
                    onClick={() => {
                      segmentsTracking('CTA Clicked', {
                        pageTitle: 'NS1 ROI',
                        object: 'NS1 ROI Page',
                        resultValue: 'CTA clicked',
                        CTA: 'Request demo CTA from tile 1',
                        location: 'NS1 ROI',
                        action: `Request demo clicked`,
                        field: '',
                      });
                      setOpenUrxModal(true);
                      setDrawerOpen(true);
                      initUrxForm(
                        environment,
                        URX_FORM.INSTANCE_ID,
                        URX_FORM.FORM_ID,
                        localeCode as IBMLocale,
                        companyId,
                      );
                      setCookie();
                    }}
                  >
                    <FormattedMessage {...messages.requestDemo} />
                  </Button>
                </ButtonSet>
              </div>
            </div>
          </div>
        )}
        {selectedTile === 'tile2' && (
          <div className="ns1roi_widget__mar3">
            <div className="bx--row padButton1">
              <div className="bx--col-lg-6">
                <p className="page_heading_3 descText">
                  <FormattedMessage {...messages.tile2description} />
                </p>
              </div>
              <div className="bx--col-lg-5 chartText chart1">
                <GaugeChart data={data1} options={options1}></GaugeChart>
                <p>
                  <FormattedMessage {...messages.chart1Text} />
                </p>
              </div>
              <div className="bx--col-lg-5 chartText chart2">
                <GaugeChart data={data3} options={options3}></GaugeChart>
                <p>
                  <FormattedMessage {...messages.chart3Text} />
                </p>
              </div>
            </div>
            <div className="bx--row">
              <div className="bx--col-lg-6">
                <ButtonSet>
                  <Button
                    id="whitepaper_btn"
                    target="_blank"
                    className="ns1roi_widget__mar6"
                    href="https://www.ibm.com/account/reg/signup?formid=urx-52911"
                    kind="tertiary"
                    renderIcon={DocumentDownload16}
                  >
                    <FormattedMessage {...messages.requestWhitepaper} />
                  </Button>
                  <Button
                    id="demo_btn_1"
                    target="_self"
                    className="ns1roi_widget__mar6"
                    href="#"
                    kind="primary"
                    onClick={() => {
                      segmentsTracking('CTA Clicked', {
                        pageTitle: 'NS1 ROI',
                        object: 'NS1 ROI Page',
                        resultValue: 'CTA clicked',
                        CTA: 'Request demo CTA from tile 2',
                        location: 'NS1 ROI',
                        action: `Request demo clicked`,
                        field: '',
                      });
                      setOpenUrxModal(true);
                      setDrawerOpen(true);
                      initUrxForm(
                        environment,
                        URX_FORM.INSTANCE_ID,
                        URX_FORM.FORM_ID,
                        localeCode as IBMLocale,
                        companyId,
                      );
                      setCookie();
                    }}
                  >
                    <FormattedMessage {...messages.requestDemo} />
                  </Button>
                </ButtonSet>
              </div>
            </div>
          </div>
        )}
        {selectedTile === 'tile3' && (
          <div className="ns1roi_widget__mar3">
            <div className="bx--row padButton1">
              <div className="bx--col-lg-10">
                <p className="page_heading_3 descText">
                  <FormattedMessage {...messages.registrar_text} />
                </p>
              </div>
              <div className="bx--col-lg-5 chartText chart1">
                <p className="ns1roi_widget_modal_btn">
                  <Button
                    id="whitepaper_btn"
                    target="_blank"
                    className="ns1roi_widget__mar6"
                    href="https://www.ibm.com/think/insights/dns-premium-benefits"
                    kind="tertiary"
                    renderIcon={DocumentDownload16}
                    onClick={() => {
                      setRegistrarClick(false);
                      handleTileSelection(
                        'tile3',
                        intl.formatMessage(messages.tile3text),
                      );
                    }}
                  >
                    <FormattedMessage {...messages.readBlogPost} />
                  </Button>
                </p>
              </div>
            </div>
            <div className="bx--row">
              <div className="bx--col-lg-6">
                <ButtonSet>
                  <Button
                    id="whitepaper_btn"
                    target="_blank"
                    className="ns1roi_widget__mar6"
                    href="https://www.ibm.com/account/reg/signup?formid=urx-52911"
                    kind="tertiary"
                    renderIcon={DocumentDownload16}
                  >
                    <FormattedMessage {...messages.requestWhitepaper} />
                  </Button>
                  <Button
                    id="demo_btn_1"
                    target="_self"
                    className="ns1roi_widget__mar6"
                    href="#"
                    kind="primary"
                    onClick={() => {
                      segmentsTracking('CTA Clicked', {
                        pageTitle: 'NS1 ROI',
                        object: 'NS1 ROI Page',
                        resultValue: 'CTA clicked',
                        CTA: 'Request demo CTA from tile 2',
                        location: 'NS1 ROI',
                        action: `Request demo clicked`,
                        field: '',
                      });
                      setOpenUrxModal(true);
                      setDrawerOpen(true);
                      initUrxForm(
                        environment,
                        URX_FORM.INSTANCE_ID,
                        URX_FORM.FORM_ID,
                        localeCode as IBMLocale,
                        companyId,
                      );
                      setCookie();
                    }}
                  >
                    <FormattedMessage {...messages.requestDemo} />
                  </Button>
                </ButtonSet>
              </div>
            </div>
          </div>
        )}
        <FormModal
          pageTitle="NS1 ROI"
          object="NS1 ROI Page"
          productTitle={'NS1 ROI'}
          pageUrl={''}
          location={'ROI'}
          bpName={bpName}
          logo={logo}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        ></FormModal>
        <Modal
          id="idx"
          size="sm"
          open={registrarClick}
          modalHeading={''}
          primaryButtonText={<FormattedMessage {...messages.modalContinue} />}
          onRequestClose={() => {
            setRegistrarClick(false);
          }}
          onRequestSubmit={() => {
            setRegistrarClick(false);
          }}
        >
          <div className=" ns1roi_widget__mar5 ml3">
            <p className="slide_text ns1roi_widget_modal_text ">
              <FormattedMessage {...messages.registrar_text} />
            </p>
            <p className="ns1roi_widget_modal_btn">
              <Button
                id="whitepaper_btn"
                target="_blank"
                className="ns1roi_widget__mar6"
                href="https://www.ibm.com/think/insights/dns-premium-benefits"
                kind="tertiary"
                renderIcon={DocumentDownload16}
                onClick={() => {
                  setRegistrarClick(false);
                  handleTileSelection(
                    'tile3',
                    intl.formatMessage(messages.tile3text),
                  );
                }}
              >
                <FormattedMessage {...messages.readBlogPost} />
              </Button>
            </p>
          </div>
        </Modal>
        {formComplete && <Thanks bpName={bpName} demo={link} />}
      </div>
    </div>
  );
};

export default NS1ROI;
